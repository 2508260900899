// Navigation Colors
export const NAVIGATION_PRIMARY = '#044D79';
export const NAVIGATION_ACTIVE = '#F2D625';
export const NAVIGATION_ACTIVE_BG = '#20202026';
export const NAVIGATION_TEXT = '#FFFFFF';
export const NAVIGATION_BTN_BG = '#FFFFFF';
export const NAVIGATION_BTN_HOVER_BG = '#DDDDDD';

// NearbyCalendar Colors
export const CALENDAR_PRIMARY = '#4D323F';
export const CALENDAR_BTN_TEXT = '#6A6A6A';
export const CALENDAR_TODAY_TEXT = '#666666';
export const CALENDAR_TEXT = '#FFFFFF';
export const CALENDAR_INACTIVE = '#FFFFFF';
export const CALENDAR_ACTIVE = '#FFFFFF';
export const CALENDAR_ACTIVE_BG = '#FFEAEA26';
// Home Colors
export const KALENDER_BG = '#C1BD88';
// export const PINNWAND_BG = '#939393';
// export const FORUM_BG = '#939393';
// export const ANPASSEN_BG = '#C1C1C1';

// Nachbarschaftstische Colors
export const NACHBARSCHAFTSTISCHE_PRIMARY = '#F7941D';
export const NACHBARSCHAFTSTISCHE_ACTIVE = '#000000';
export const NACHBARSCHAFTSTISCHE_ACTIVE_BG = '#20202026';
export const NACHBARSCHAFTSTISCHE_TEXT = '#FFFFFF';
export const NACHBARSCHAFTSTISCHE_INACTIVE = '#FFFFFF';
export const NACHBARSCHAFTSTISCHE_BTN_TEXT = '#6A6A6A';
export const NACHBARSCHAFTSTISCHE_CARD_BORDER = '#EAEAED';

// Veranstaltungen Colors
export const VERANSTALTUNGEN_PRIMARY = '#9E2129';
export const VERANSTALTUNGEN_ACTIVE = '#FFFFFF';
export const VERANSTALTUNGEN_ACTIVE_BG = '#FFFFFF33';
export const VERANSTALTUNGEN_TEXT = '#FFFFFF';
export const VERANSTALTUNGEN_INACTIVE = '#FFFFFF';
export const VERANSTALTUNGEN_BTN_TEXT = '#6A6A6A';

// Information & Service Colors
export const INFORMATIONEN_SERVICE_PRIMARY = '#005596';
export const INFORMATIONEN_SERVICE_TEXT = '#FFFFFF';
export const INFORMATIONEN_SERVICE_ACTIVE = '#FFFFFF';
export const INFORMATIONEN_SERVICE_ACTIVE_BG = '#FFFFFF26';
export const INFORMATIONEN_SERVICE_INACTIVE = '#FFFFFF';

// Aktuelles Colors
export const NEUIGKEITEN_PRIMARY = '#770FAF';
export const NEUIGKEITEN_ACTIVE = '#000000';
export const NEUIGKEITEN_TEXT = '#FFFFFF';

// Fit & Gesund Colors
export const FITHEALTH_PRIMARY = '#007654';
export const FITHEALTH_ACTIVE = '#000000';
export const FITHEALTH_TEXT = '#FFFFFF';
export const FITHEALTH_ACTIVE_BG = '#FFFFFF26';
export const FITHEALTH_INACTIVE = '#FFFFFF';

// Einstellungen Colors
export const SETTINGS_LABEL = '#8A8A8AD3';
export const SETTINGS_PROFILE_PIC = '#343434D3';
export const SETTINGS_PROFILE_PIC_EDIT = '#2E7D8BFF';
export const SETTINGS_INPUT_BACKGROUND = '#9DB97C30';
export const SETTINGS_PRIMARY = '#87B922';
export const SETTINGS_DISABLED_BG = '#FFFFFF';
export const SETTINGS_DISABLED_FONT_COLOR = '#E6E6E6';

// Hilfe Colors
export const HILFE_ACTIONS_BG = '#E6E6E6';
export const HILFE_DETAILS_BG = '#E6E6E6';
export const HILFE_EXPAND_BUTTON = '#425A11';
export const HILFE_EXPAND_BUTTON_EXPANDED = '#87B922';
export const HILFE_LINK_BG = '#87B922';
export const HILFE_SUMMERY_LEFT_BORDER = '#E6E6E6';

// MeineNahe Colors
export const MEINE_NAHE_PRIMARY = '#87B922';
export const MEINE_NAHE_DISABLED_BG = '#FFFFFF';
export const MEINE_NAHE_DISABLED_FONT_COLOR = '#E6E6E6';

// Pinnwand Colors
export const PINNWAND_PRIMARY = '#4BB535';
export const PINNWAND_ACTIVE = '#000000';
export const PINNWAND_TEXT = '#000000';
export const PINNWAND_INACTIVE = '#FFFFFF';
export const PINNWAND_ACTIVE_BG = '#20202026';
export const PINNWAND_BTN_TEXT = '#FFFFFF';

export const COMING_SOON_TEXT = '#666666';
